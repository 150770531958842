(function ($) {
  var active_stylesheet_name = 'vtx-css';
  var selector_toggle_high_contrast = '.toggle-accessibility-stylesheet';

  function get_menu_selected_stylesheet(checked_element) {
    if ($(checked_element).prop('checked')) {
      return 'high_contrast';
    }
    return 'vtx-css';
  }
  function set_menu_selected_stylesheet(stylesheet) {
    if ('high_contrast' === stylesheet) {
      $.each($(selector_toggle_high_contrast), function (i, toggle) {
        $(toggle).prop('checked', true);
      });
    }
  }

  function update_contrast_in_html(stylesheet) {
    if ('high_contrast' === stylesheet) {
      $('html').addClass('high-contrast');
    } else {
      $('html').removeClass('high-contrast');
    }
  }

  function save_user_stylesheet() {
    localStorage.setItem('active_stylesheet_name', active_stylesheet_name);
  }
  function load_saved_user_stylesheet() {
    active_stylesheet_name =
      null !== localStorage.getItem('active_stylesheet_name')
        ? localStorage.getItem('active_stylesheet_name')
        : active_stylesheet_name;
  }

  function init_toggle_high_contrast() {
    set_menu_selected_stylesheet(active_stylesheet_name);
    $(selector_toggle_high_contrast).on('change', function (e) {
      active_stylesheet_name = get_menu_selected_stylesheet(e.currentTarget);
      save_user_stylesheet();

      update_contrast_in_html(active_stylesheet_name);
    });
  }

  // Early call to restore the state from the LocalStorage
  load_saved_user_stylesheet();

  update_contrast_in_html(active_stylesheet_name);

  window.addEventListener('DOMContentLoaded', function () {
    init_toggle_high_contrast();
  });
})(jQuery); // end conflict sage jQuery
